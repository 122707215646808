import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { registerLicense } from '@syncfusion/ej2-base';
// Registering Syncfusion license key
registerLicense(
   'ORg4AjUWIQA/Gnt2VVhiQlFadVlJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdk1hWX5XcnBRR2JeWEw='
);

const rootElement = document.getElementById('root');
const queryClient = new QueryClient();
ReactDOM.render(
   <QueryClientProvider client={queryClient}>
      <App />
      <ReactQueryDevtools initialIsOpen />
   </QueryClientProvider>,
   rootElement
);
